import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import Old2005BlogPage from "../../components/Old2005BlogPage";

const OldBlogPage = () => (
  <Layout>
    <Seo title="Old 2005 Blog—August 2007" />
	<Old2005BlogPage>
		<h2>August 2007</h2>




		<h3 className="blogdate">
		Friday, August 31, 2007
		</h3>






		<h4 className="blogitemtitle">
		Randomness from Wellington
		</h4>





			<div className="blogitembody">
			<span>There are some people behind me in the office that are engrossed in a conversation about their performance reviews.  I am filled with shuddering memories of past employment in a past corporation.</span><br /><br /><span>Corporations are hilarious - big clumsy puff-marshmallow-men stumbling through the city, like in the movie 'Ghostbusters'.  I want to blast them with Egon and the boys and watch them burst and melt into sticky bits.  The onlookers will be filled with glee!</span><br /><br /><span>Here's something intriguing:  Wellington city planners/caretakers have planted silverbeet in the middle island (of the road) on Lambton Quay.  It's beautiful, and potentially delicious (silverbeet = chard) - I want to make a big green, red, and yellow stew of earthy bitterness for all the suits that don't have time to cook for themselves.  That's why it's there, n'est-ce pas?</span><br /><br /><span>Some random thoughts popped in my mind this afternoon; they are observations about Wellington.  First, don't cross any street, at any crosswalk, without definite confirmation that cars are going to stop for you.  In general, cars will not stop, so you better be looking!  Second, drive cautiously, because you never know when oncoming cars have right-of-way to turn through your lane.  It's true - there are unassuming places where you must stop if any oncoming traffic wants to turn through your lane!  Caution - this defies all North American logic!</span><br /><span></span><br />
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 4:54 PM &nbsp;
			</div>





		<p>
			<Link to="/blog2005/">Go back to the blog index page</Link>
		</p>
</Old2005BlogPage>
  </Layout>
)

export default OldBlogPage;
